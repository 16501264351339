<template>
    <label :for="name" class="baseCheckbox">
        <div class="cb">
            <input
                :id="name"
                :name="name"
                type="checkbox"
                :checked="modelValue"
                @change="checked"
                :disabled="disabled"
            >
            <span class="toggle" :class="{'disabled': disabled}"><slot/></span>
        </div>
        <span v-if="label" class="label">
            {{ $t(label) }}
        </span>
        <template v-if="infoText">
            <info-circle-icon :infoText="infoText"/>
        </template>
    </label>
</template>

<script>
import {ref, watch} from 'vue';
import InfoCircleIcon from '../../Icons/InfoCircleIcon.vue';

export default {
    name: 'BaseCheckbox',
    components: {InfoCircleIcon},
    emits: ['checked', 'update:modelValue'],
    props: {
        name: {
            type: String,
            required: false,
        },
        label: {
            required: false,
            type: String,
        },
        modelValue: {
            required: false,
            type: [Boolean, String],
        },
        disabled: {required: false, type: Boolean},
        infoText: {type: String, required: false},
    },
    setup(props, {emit}) {
        const rawValue = ref(false);

        watch(() => props.modelValue, (newValue) => {
            if (rawValue.value !== newValue) {
                return rawValue.value = newValue;
            }
        }, {immediate: true});


        const checked = (event) => {
            emit('checked', event.target.checked);

            emit('update:modelValue', event.target.checked);
        };

        return {
            rawValue,
            checked,
        };
    },
};
</script>

<style lang="scss" scoped>
label.baseCheckbox {
    display: flex;

    &:hover {
        cursor: pointer;
    }

    .cb {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background-color: var(--text-inactive);
        border: 1px solid var(--text-secondary);
        border-radius: .2rem;
        margin: auto 1.5rem auto 0;
        position: relative;

        input[type="checkbox"] {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;

            &:checked + .toggle {
                background-color: var(--text-secondary);

                &.disabled {
                    background-color: var(--corebox-warning);
                }

            }
        }

        .toggle {
            background-color: transparent;
            width: 0.8rem;
            height: 0.8rem;
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            transition: background-color 0.4s ease-in-out;
        }
    }
}
</style>
